import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Header from './Header/Header';

import PageNotFound from './PageNotFound';

import Home from "./Home/Home"

function App() {
  return (
    <div className="App">
      <div className='mainContainer'>
      <Router>
        <Header></Header>
        <div className="appContainer">
          <Routes>
            {/* Home */}
            <Route path="/" element={<Home />} />
            {/* 404 Error */}
            <Route path="*" element={<PageNotFound/>}/>
          </Routes>
        </div>
      </Router>
      </div>
    </div>
  );
}

export default App;

import { useState } from "react";

function Poll() {
  const [song, setSong] = useState("");
  return (
    <div className="card poll">
      <div className="titleArea">
        <h3>Karaoke Time!</h3>
      </div>
      <div className="bottomText">
        {song === "" ? (
          <div className="pollContainer">
            <h6>Vote to sing your favorite song in the 4th quarter!</h6>
            <button
              className="actionButton"
              onClick={() => setSong("Don't Stop Believin'")}
            >
              Don't Stop Believin'<caption>Journey</caption>
            </button>
            <button
              className="actionButton"
              onClick={() => setSong("Take me Home, Country Roads")}
            >
              Take me Home, Country Roads<caption>John Denver</caption>
            </button>
            <button
              className="actionButton"
              onClick={() => setSong("Livin' on a Prayer")}
            >
              Livin' on a Prayer<caption>Bon Jovi</caption>
            </button>
          </div>
        ) : (
          <div>
            <h5>Thanks! Stick around to see which song wins!</h5>
            <p>Your Pick: {song}</p>
          </div>
        )}
      </div>
    </div>
  );
}
export default Poll;

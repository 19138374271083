import logo from "../img/logo.png"
import "./header.css"
import { useState } from "react";
  
function Header() {
    return (
        <div className={"headerContainer"}>
            <img src={logo} className="logo"/>
        </div>
  );
}

export default Header;
import "./home.css";

import { Link } from "react-router-dom";
import Pdf from "../img/parking.pdf";
import Poll from "../Poll";
import mag from "../img/azbowlmag.pdf";
import sportsbook from "../img/sportsbook.png";

function Home() {
  return (
    <div className="content">
      <div className="animationModule">
        <h1 className="gradient">Gameday Central</h1>
      </div>
      <div className="cards">
        <div className="card program">
          <div className="titleArea">
            <h3>Digital Program</h3>
          </div>
          <div className="bottomText">
            <h6>Get in the game with our digital program</h6>
            <a
              href={
                "https://www.canva.com/design/DAF2_CIwfz4/5mUfys5MXW5nbXCC9CvKJw/view"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="actionButton">Open Program</button>
            </a>
          </div>
        </div>
        <Poll />
        <div className="card">
          <div className="titleArea bowlbash"></div>
          <div className="bottomText">
            <h6>
              Taco Bell NYE Bowl Bash - The best New Year's Eve Party in Tucson.
            </h6>
            <a
              href="https://thearizonabowl.com/event/2023-taco-bell-new-years-eve-downtown-bowl-bash/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="actionButton">Learn More</button>
            </a>
          </div>
        </div>
        <div className="card">
          <div className="titleArea">
            <h3>Parking Map</h3>
          </div>
          <div className="bottomText">
            <h6>Check out available parking options</h6>
            <a href={Pdf} target="_blank" rel="noopener noreferrer">
              <button className="actionButton">Open Map</button>
            </a>
          </div>
        </div>
        {/* <div className="card sportsbook">
                <div className="titleArea">
                    <img src={sportsbook} />
                </div>
                <div className="bottomText">
                    <a href="https://apps.apple.com/us/app/barstool-sportsbook-casino/id1474416533" target="_blank" rel="noopener noreferrer">
                        <button className="actionButton">
                            Download the App
                        </button>
                    </a>
                </div>
            </div>
            <div className="card">
                <div className="titleArea">
                    <h3>UFC Fight Pass</h3>
                </div>
                <div className="bottomText">
                    <h6>Get 80% OFF Your First Month</h6>
                    <a href="http://www.ufcfightpass.com/signup" target="_blank" rel="noopener noreferrer">
                        <button className="actionButton">
                            Signup Now
                        </button>
                    </a>
                </div>
            </div> */}
      </div>
    </div>
  );
}
export default Home;
